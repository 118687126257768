import React, { useContext, useEffect } from 'react';

import ENV from '~/constants/ENV';
import { isStorybookEnv } from '~/helpers';
import Logger from '~/helpers/logger';
import {
  getCurrentRecruitmentChoice,
  getCurrentSegmentEthnicity,
  getCurrentSegmentFirstGenerationCollegeStudent,
  getCurrentSegmentGender,
  getCurrentSegmentStates,
  getCurrentSegmentUserState,
  getEducatorConnections,
} from '~/helpers/pendo/analyticGetters';
import { initPendo, loadPendo } from '~/helpers/pendo/pendoInit';
import { getStudentGradeLevel } from '~/helpers/pendo/reformatStudentData';
import { notImplemented } from '~/helpers/type';
import useMyProfile from '~/hooks/useMyProfile';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';

interface IPendoContextProps {
  setVisitorId: (id: string) => void;
}

export const PendoContext = React.createContext<IPendoContextProps>({
  setVisitorId: notImplemented,
});

const PendoContextProvider = PendoContext.Provider;

export function usePendoContext() {
  const context = useContext(PendoContext);

  if (!context) {
    throw new Error('usePendoContext must be used within a PendoProvider');
  }
  return context;
}

const logger = new Logger('PendoProvider');

export const PendoProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const profile = useMyProfile();
  const [visitorId, setVisitorId] = React.useState<string>('');
  const reactNativeObj = useReactNativeObj();

  useEffect(() => {
    if (!isStorybookEnv) {
      logger.debug('Loading pendo');
      loadPendo(ENV.PENDO_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Always prefer profile visitor ID over manual
    const visitorIdToUse = profile?.data?.myProfile?.encourageUser?.visitorId || visitorId;

    const rnAppVersion = reactNativeObj ? `${reactNativeObj?.build?.marketingVersion} (${reactNativeObj?.build?.buildNumber})` : 'web-browser';
    const rnDeviceModel = reactNativeObj ? `${reactNativeObj?.platform?.model}` : 'web-browser';
    const rnMobilePlatform = reactNativeObj ? `rn-${reactNativeObj?.platform?.OS}` : 'web-browser';
    const rnMobilePlatformVersion = reactNativeObj ? `${reactNativeObj?.platform?.Version}` : 'web-browser';

    const screenWidth = reactNativeObj ? `${reactNativeObj?.platform?.screenWidth}` : window?.screen?.width;
    const screenHeight = reactNativeObj ? `${reactNativeObj?.platform?.screenHeight}` : window?.screen?.height;

    let screenOrientation;
    if (reactNativeObj) {
      screenOrientation = `rn-${reactNativeObj?.platform?.screenOrientation}`;
    } else {
      screenOrientation = window?.screen?.orientation?.type.includes('portrait') ? 'web-browser-portrait' : 'web-browser-landscape';
    }

    const screenSize = reactNativeObj
      ? `${reactNativeObj?.platform?.screenWidth} X ${reactNativeObj?.platform?.screenHeight}`
      : `${window?.screen?.width} X ${window?.screen?.height}`;

    if (visitorIdToUse && !isStorybookEnv) {
      const myProfile = profile?.data?.myProfile;
      // https://app.pendo.io/s/6565319938015232/admin/mappings
      const visitorData = myProfile
        ? {
            collegerecruitment: getCurrentRecruitmentChoice(myProfile),
            currentenviroment: process.env.NODE_ENV,
            defaultenviroment: process.env.NODE_NNV,
            educatorconnection: getEducatorConnections(myProfile),
            ethnicity: getCurrentSegmentEthnicity(myProfile),
            firstgenerationcollegestudent: getCurrentSegmentFirstGenerationCollegeStudent(myProfile),
            gender: getCurrentSegmentGender(myProfile),
            gradelevel: getStudentGradeLevel(myProfile?.highSchoolEnrollment?.graduationYear) ?? 'unknown',
            graduationyear: (myProfile?.highSchoolEnrollment?.graduationYear ?? 'unknown').toString(),
            productionbuildoutofbounds: process.env.NODE_ENV === 'production',
            rnAppVersion,
            rnDeviceModel,
            rnMobilePlatform,
            rnMobilePlatformVersion,
            screenHeight,
            screenOrientation,
            screenSize,
            screenWidth,
            statesofinterest: getCurrentSegmentStates(myProfile),
            userstate: getCurrentSegmentUserState(myProfile),
          }
        : {};

      logger.debug('Initializing Pendo', { pendo_key: ENV.PENDO_KEY, visitorIdToUse });
      initPendo(visitorIdToUse, visitorData);
    }
  }, [profile, reactNativeObj, visitorId]);

  return <PendoContextProvider value={{ setVisitorId }}>{children}</PendoContextProvider>;
};

//
// production_build_out_of_bounds: null,
// student_source: null,
