/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AverageClassRank {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export enum CareerOrder {
  ALPHABETICAL = 'ALPHABETICAL',
  LEAST_EDUCATION = 'LEAST_EDUCATION',
  MOST_DEMAND = 'MOST_DEMAND',
  MOST_EDUCATION = 'MOST_EDUCATION',
  MOST_RECENT = 'MOST_RECENT',
  MOST_SALARY = 'MOST_SALARY',
  RELEVANCY = 'RELEVANCY',
}

export enum CollegeAOSClickActivityType {
  COLLEGE_APPLY = 'COLLEGE_APPLY',
  COLLEGE_EMAIL = 'COLLEGE_EMAIL',
  COLLEGE_INQUIRY = 'COLLEGE_INQUIRY',
  COLLEGE_PROFILE = 'COLLEGE_PROFILE',
  COLLEGE_VISIT = 'COLLEGE_VISIT',
  COLLEGE_WWW = 'COLLEGE_WWW',
}

export enum CollegeCampusPopulationSize {
  RANGE_1 = 'RANGE_1',
  RANGE_2 = 'RANGE_2',
  RANGE_3 = 'RANGE_3',
  RANGE_4 = 'RANGE_4',
  RANGE_5 = 'RANGE_5',
}

export enum CollegeGeographicSetting {
  IN_A_BIG_CITY = 'IN_A_BIG_CITY',
  IN_A_MIDSIZED_CITY = 'IN_A_MIDSIZED_CITY',
  IN_A_SMALL_TOWN = 'IN_A_SMALL_TOWN',
  NO_PREFERENCE = 'NO_PREFERENCE',
}

export enum CollegeHoursFromHome {
  NO_PREFERENCE = 'NO_PREFERENCE',
  OVER_THREE_HOUR = 'OVER_THREE_HOUR',
  UP_TO_ONE_AND_HALF_HOUR = 'UP_TO_ONE_AND_HALF_HOUR',
  UP_TO_THREE_HOUR = 'UP_TO_THREE_HOUR',
}

/**
 * What are the top three things you care about the most when applying to a college or university? (Choose up to THREE)
 */
export enum CollegeMindsetCareAboutMost {
  ACADDIVERSITY = 'ACADDIVERSITY',
  ACADENV = 'ACADENV',
  ACADSTR = 'ACADSTR',
  AFFORD = 'AFFORD',
  CAREERPREP = 'CAREERPREP',
  FLEXDEL = 'FLEXDEL',
  NOT_SURE = 'NOT_SURE',
  PHYSENV = 'PHYSENV',
  SOCIALENV = 'SOCIALENV',
}

/**
 * What are the top five experiences you expect to learn the most from during college? (Choose up to FIVE)
 */
export enum CollegeMindsetExpectationsForEducation {
  EXPCOMMUNICATE = 'EXPCOMMUNICATE',
  EXPCULTURAL = 'EXPCULTURAL',
  EXPDISPROB = 'EXPDISPROB',
  EXPFOUNDATION = 'EXPFOUNDATION',
  EXPFRIENDS = 'EXPFRIENDS',
  EXPGRADSCHOOL = 'EXPGRADSCHOOL',
  EXPJOB = 'EXPJOB',
  EXPLEADER = 'EXPLEADER',
  EXPLIFELEARN = 'EXPLIFELEARN',
  EXPMASTER = 'EXPMASTER',
  EXPMEANING = 'EXPMEANING',
  EXPPROBLEMS = 'EXPPROBLEMS',
  EXPRESEARCH = 'EXPRESEARCH',
  EXPSCISKILLS = 'EXPSCISKILLS',
  EXPSKILLS = 'EXPSKILLS',
  EXPTALENTS = 'EXPTALENTS',
  EXPWORLDSKILLS = 'EXPWORLDSKILLS',
  NOT_SURE = 'NOT_SURE',
}

/**
 * What are the top five experiences you expect to learn the most from during college? (Choose up to FIVE)
 */
export enum CollegeMindsetExperiencesToLearnFromMost {
  LRNACADCRSWRK = 'LRNACADCRSWRK',
  LRNADMININTER = 'LRNADMININTER',
  LRNCLUBPART = 'LRNCLUBPART',
  LRNCOMMSRVC = 'LRNCOMMSRVC',
  LRNCULTURALEVENTS = 'LRNCULTURALEVENTS',
  LRNFACULTY = 'LRNFACULTY',
  LRNFACULTYRSRCH = 'LRNFACULTYRSRCH',
  LRNINTERACT = 'LRNINTERACT',
  LRNINTERN = 'LRNINTERN',
  LRNNONWORKSTUDY = 'LRNNONWORKSTUDY',
  LRNSTUDYABROAD = 'LRNSTUDYABROAD',
  LRNWORKSTUDY = 'LRNWORKSTUDY',
  NOT_SURE = 'NOT_SURE',
}

export enum CollegeSocialBehavior {
  MINIMAL_SOCIAL_RULES = 'MINIMAL_SOCIAL_RULES',
  MODERATE_SOCIAL_RULES = 'MODERATE_SOCIAL_RULES',
  NOT_SURE = 'NOT_SURE',
  STRICT_SOCIAL_RULES = 'STRICT_SOCIAL_RULES',
}

export enum CollegeType {
  APPRENTICESHIP_WORKFORCE = 'APPRENTICESHIP_WORKFORCE',
  CAREER_TECH = 'CAREER_TECH',
  FOUR_YEAR_PRIVATE = 'FOUR_YEAR_PRIVATE',
  FOUR_YEAR_PUBLIC = 'FOUR_YEAR_PUBLIC',
  MILITARY = 'MILITARY',
  NOT_SURE = 'NOT_SURE',
  NURSING = 'NURSING',
  TWO_YEAR_PRIVATE = 'TWO_YEAR_PRIVATE',
  TWO_YEAR_PUBLIC = 'TWO_YEAR_PUBLIC',
}

export enum Control {
  PRIVATE = 'PRIVATE',
  PROPRIETARY = 'PROPRIETARY',
  PUBLIC = 'PUBLIC',
  UNKNOWN = 'UNKNOWN',
}

export enum CoursePlan {
  NOT_TAKING = 'NOT_TAKING',
  TAKEN = 'TAKEN',
  TAKING = 'TAKING',
}

export enum CourseTitle {
  ALGEBRA_1 = 'ALGEBRA_1',
  ALGEBRA_2 = 'ALGEBRA_2',
  ART = 'ART',
  BIOLOGY = 'BIOLOGY',
  CALCULUS = 'CALCULUS',
  CHEMISTRY = 'CHEMISTRY',
  CIVICS = 'CIVICS',
  DRAMA_THEATER = 'DRAMA_THEATER',
  ECONOMICS = 'ECONOMICS',
  ENGLISH_10 = 'ENGLISH_10',
  ENGLISH_11 = 'ENGLISH_11',
  ENGLISH_12 = 'ENGLISH_12',
  ENGLISH_8 = 'ENGLISH_8',
  ENGLISH_9 = 'ENGLISH_9',
  ENGLISH_OTHER = 'ENGLISH_OTHER',
  FRENCH = 'FRENCH',
  GENERAL_SCIENCE = 'GENERAL_SCIENCE',
  GEOGRAPHY = 'GEOGRAPHY',
  GEOMETRY = 'GEOMETRY',
  GERMAN = 'GERMAN',
  HISTORY_OTHER = 'HISTORY_OTHER',
  HISTORY_US = 'HISTORY_US',
  HISTORY_WORLD = 'HISTORY_WORLD',
  LANGUAGE_OTHER = 'LANGUAGE_OTHER',
  MATH_COMPUTER = 'MATH_COMPUTER',
  MATH_OTHER = 'MATH_OTHER',
  MUSIC = 'MUSIC',
  PHYSICS = 'PHYSICS',
  PSYCHOLOGY = 'PSYCHOLOGY',
  SPANISH = 'SPANISH',
  TRIGONOMETRY = 'TRIGONOMETRY',
}

export enum EncouraAcceptRateBracket {
  F10_20 = 'F10_20',
  F20_35 = 'F20_35',
  F35_70 = 'F35_70',
  LESS_THAN_10 = 'LESS_THAN_10',
  MORE_THAN_70 = 'MORE_THAN_70',
}

export enum EncouraCollegeSortField {
  ACCEPTANCE_RATE = 'ACCEPTANCE_RATE',
  AVG_DEBT_AT_GRAD = 'AVG_DEBT_AT_GRAD',
  FIN_AID_PERCENT = 'FIN_AID_PERCENT',
  IN_STATE_TUITION = 'IN_STATE_TUITION',
  NAME = 'NAME',
  OUT_STATE_TUITION = 'OUT_STATE_TUITION',
}

export enum EncouraHsGpaBracket {
  F0_25 = 'F0_25',
  F25_30 = 'F25_30',
  F30_35 = 'F30_35',
  F35_39 = 'F35_39',
  MORE_THAN_39 = 'MORE_THAN_39',
}

export enum EncouraInstitutionSizeBracket {
  F0_1K = 'F0_1K',
  F10K_20K = 'F10K_20K',
  F1K_5K = 'F1K_5K',
  F5K_10K = 'F5K_10K',
  MORE_THAN_20K = 'MORE_THAN_20K',
}

export enum EncouraOutStateCostBracket {
  F0_10K = 'F0_10K',
  F10K_25K = 'F10K_25K',
  F25K_50K = 'F25K_50K',
  MORE_THAN_50K = 'MORE_THAN_50K',
}

export enum EncouraRegion {
  MIDWEST = 'MIDWEST',
  NORTH = 'NORTH',
  SOUTH = 'SOUTH',
  WEST = 'WEST',
}

export enum EncourageUserCommunicationPreference {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum EncourageUserGender {
  ANOTHER_GENDER = 'ANOTHER_GENDER',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
}

export enum EncourageUserPrivacyProgram {
  B2B_USER_ACCOUNT = 'B2B_USER_ACCOUNT',
  B2C_USER_ACCOUNT = 'B2C_USER_ACCOUNT',
  ENCOURAGE = 'ENCOURAGE',
}

export enum EncourageUserPushNotificationTopic {
  ANDROID = 'ANDROID',
  GENERAL = 'GENERAL',
  GRAD_YEAR_2022 = 'GRAD_YEAR_2022',
  GRAD_YEAR_2023 = 'GRAD_YEAR_2023',
  GRAD_YEAR_2024 = 'GRAD_YEAR_2024',
  GRAD_YEAR_2025 = 'GRAD_YEAR_2025',
  GRAD_YEAR_2026 = 'GRAD_YEAR_2026',
  GRAD_YEAR_2027 = 'GRAD_YEAR_2027',
  GRAD_YEAR_2028 = 'GRAD_YEAR_2028',
  GRAD_YEAR_2029 = 'GRAD_YEAR_2029',
  GRAD_YEAR_2030 = 'GRAD_YEAR_2030',
  GRAD_YEAR_2031 = 'GRAD_YEAR_2031',
  GRAD_YEAR_2032 = 'GRAD_YEAR_2032',
  GRAD_YEAR_2033 = 'GRAD_YEAR_2033',
  GRAD_YEAR_2034 = 'GRAD_YEAR_2034',
  GRAD_YEAR_2035 = 'GRAD_YEAR_2035',
  GRAD_YEAR_2036 = 'GRAD_YEAR_2036',
  GRAD_YEAR_2037 = 'GRAD_YEAR_2037',
  GRAD_YEAR_2038 = 'GRAD_YEAR_2038',
  GRAD_YEAR_2039 = 'GRAD_YEAR_2039',
  GRAD_YEAR_2040 = 'GRAD_YEAR_2040',
  IOS = 'IOS',
  QA = 'QA',
}

export enum ExtracurricularInterest {
  ACADEMIC_HONORS_CLUB = 'ACADEMIC_HONORS_CLUB',
  ART_DESIGN_CLUB = 'ART_DESIGN_CLUB',
  BUSINESS_ENTREPRENEUR_CLUB = 'BUSINESS_ENTREPRENEUR_CLUB',
  CHEERLEADING = 'CHEERLEADING',
  CHURCH_RELIGIOUS_ORGANIZATIONS = 'CHURCH_RELIGIOUS_ORGANIZATIONS',
  CLUB_SPORTS = 'CLUB_SPORTS',
  COMMUNITY_SERVICE_ORGANIZATIONS_CLUBS = 'COMMUNITY_SERVICE_ORGANIZATIONS_CLUBS',
  CULTURAL_ORGANIZATIONS = 'CULTURAL_ORGANIZATIONS',
  DANCE_TEAM = 'DANCE_TEAM',
  DEBATE_FORENSICS = 'DEBATE_FORENSICS',
  DRAMA_THEATRE = 'DRAMA_THEATRE',
  ENVIRONMENTAL_CLUB = 'ENVIRONMENTAL_CLUB',
  EQUESTRIAN_RODEO = 'EQUESTRIAN_RODEO',
  E_SPORTS_GAMING = 'E_SPORTS_GAMING',
  FAITH_RELIGIOUS_ORGANIZATIONS = 'FAITH_RELIGIOUS_ORGANIZATIONS',
  FRATERNITY_SOROTITY = 'FRATERNITY_SOROTITY',
  HUNTING_FISHING = 'HUNTING_FISHING',
  INTRAMURAL_SPORTS = 'INTRAMURAL_SPORTS',
  INTRUMENTAL_MUSIC = 'INTRUMENTAL_MUSIC',
  LANGUAGE_CLUB = 'LANGUAGE_CLUB',
  LEADERSHIP = 'LEADERSHIP',
  MARTIAL_ARTS = 'MARTIAL_ARTS',
  MATH_SCIENCE_CLUB = 'MATH_SCIENCE_CLUB',
  NOT_SURE = 'NOT_SURE',
  OUTDOOR_ADVENTURE_RECREATION = 'OUTDOOR_ADVENTURE_RECREATION',
  PUBLICATIONS = 'PUBLICATIONS',
  RADIO_TV = 'RADIO_TV',
  RESERVE_OFFICER_TRAINING_CORPS = 'RESERVE_OFFICER_TRAINING_CORPS',
  ROBOTICS = 'ROBOTICS',
  SERVICE_VOLUNTEERING = 'SERVICE_VOLUNTEERING',
  STUDENT_GOVERNMENT = 'STUDENT_GOVERNMENT',
  STUDENT_JOURNALISM = 'STUDENT_JOURNALISM',
  STUDY_ABROAD = 'STUDY_ABROAD',
  TECH_ENGINEERING_CLUB = 'TECH_ENGINEERING_CLUB',
  THEATRE_PERFORMING_ARTS = 'THEATRE_PERFORMING_ARTS',
  VARSITY_SPORTS = 'VARSITY_SPORTS',
  VOCAL_MUSIC = 'VOCAL_MUSIC',
}

export enum Gender {
  ANOTHER_GENDER = 'ANOTHER_GENDER',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
}

export enum GlobalSourceType {
  CR = 'CR',
  E_MCO = 'E_MCO',
  LEADGENFRM = 'LEADGENFRM',
  MKTGSRVFRM = 'MKTGSRVFRM',
}

export enum Grade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
}

export enum GradePointAverage {
  A_MINUS_TO_A = 'A_MINUS_TO_A',
  B_MINUS_TO_B = 'B_MINUS_TO_B',
  B_TO_B_PLUS = 'B_TO_B_PLUS',
  C_MINUS_TO_C = 'C_MINUS_TO_C',
  C_TO_B_MINUS = 'C_TO_B_MINUS',
  D_MINUS_TO_D = 'D_MINUS_TO_D',
  D_TO_C_MINUS = 'D_TO_C_MINUS',
}

export enum JourneyType {
  COLLEGE_BOUND = 'COLLEGE_BOUND',
  EIGHTH_TENTH = 'EIGHTH_TENTH',
}

export enum LearnerMilestoneModuleType {
  CHECKLIST = 'CHECKLIST',
  STATIC = 'STATIC',
  VIDEO = 'VIDEO',
}

export enum LearnerMilestoneStatusType {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NOT_STARTED = 'NOT_STARTED',
}

export enum MajorOrder {
  ALPHABETICAL = 'ALPHABETICAL',
  MAJOR_AREA_ALPHABETICAL = 'MAJOR_AREA_ALPHABETICAL',
  RELEVANCY = 'RELEVANCY',
}

export enum MilitaryBranch {
  AIR_FORCE = 'AIR_FORCE',
  ARMY = 'ARMY',
  COAST_GUARD = 'COAST_GUARD',
  MARINES = 'MARINES',
  NAVY = 'NAVY',
  NOT_SURE = 'NOT_SURE',
  SPACE_FORCE = 'SPACE_FORCE',
}

export enum MilitaryInterest {
  COLLEGE_BASED_ROTC = 'COLLEGE_BASED_ROTC',
  ENLIST_AFTER_HIGH_SCHOOL = 'ENLIST_AFTER_HIGH_SCHOOL',
  MILITARY_ACADEMY_OR_COLLEGE = 'MILITARY_ACADEMY_OR_COLLEGE',
  MILITARY_RESERVES = 'MILITARY_RESERVES',
  NATIONAL_GUARD = 'NATIONAL_GUARD',
  NO_MILITARY_INTEREST = 'NO_MILITARY_INTEREST',
  OTHER_MILITARY_PLANS = 'OTHER_MILITARY_PLANS',
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 * Survey questions behavior to be evaluated by client
 */
export enum QuizSurveyQuestionsBehavior {
  FORWARD_TO_FIRST_WEAK = 'FORWARD_TO_FIRST_WEAK',
  SHOW_ALL = 'SHOW_ALL',
  SHOW_ALL_NOT_ANSWERED = 'SHOW_ALL_NOT_ANSWERED',
  SHOW_ONLY_WEAK = 'SHOW_ONLY_WEAK',
}

export enum Race {
  AMERICAN_INDIAN_ALASKA_NATIVE = 'AMERICAN_INDIAN_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_AFRICAN_AMERICAN = 'BLACK_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
  WHITE = 'WHITE',
}

export enum Religion {
  AFRICAN_METHODIST_EPISCOPAL = 'AFRICAN_METHODIST_EPISCOPAL',
  AFRICAN_METHODIST_EPISCOPAL_ZION = 'AFRICAN_METHODIST_EPISCOPAL_ZION',
  ANGLICAN = 'ANGLICAN',
  ASSEMBLIES_OF_GOD = 'ASSEMBLIES_OF_GOD',
  BAHA_I = 'BAHA_I',
  BAPTIST = 'BAPTIST',
  BUDDHISM = 'BUDDHISM',
  CHRISTIAN_DISCIPLES_OF_CHRIST = 'CHRISTIAN_DISCIPLES_OF_CHRIST',
  CHRISTIAN_REFORMED_CHURCH_OF_AMERICA = 'CHRISTIAN_REFORMED_CHURCH_OF_AMERICA',
  CHRISTIAN_SCIENCE = 'CHRISTIAN_SCIENCE',
  CHURCHES_OF_CHRIST = 'CHURCHES_OF_CHRIST',
  CHURCH_OF_GOD = 'CHURCH_OF_GOD',
  CHURCH_OF_JESUS_CHRIST_OF_LATTER_DAY_SAINTS = 'CHURCH_OF_JESUS_CHRIST_OF_LATTER_DAY_SAINTS',
  CHURCH_OF_NAZARENE = 'CHURCH_OF_NAZARENE',
  CHURCH_OF_THE_BRETHREN = 'CHURCH_OF_THE_BRETHREN',
  EASTERN_ORTHODOX = 'EASTERN_ORTHODOX',
  EPISCOPAL = 'EPISCOPAL',
  EVANGELICAL = 'EVANGELICAL',
  EVANGELICAL_LUTHERAN_CHURCH = 'EVANGELICAL_LUTHERAN_CHURCH',
  HINDUISM = 'HINDUISM',
  JAINISM = 'JAINISM',
  JEHOVAHS_WITNESSES = 'JEHOVAHS_WITNESSES',
  JUDAISM = 'JUDAISM',
  LUTHERAN = 'LUTHERAN',
  MENNONITE = 'MENNONITE',
  METHODIST = 'METHODIST',
  MUSLIM = 'MUSLIM',
  NO_RELIGIOUS_AFFILIATION = 'NO_RELIGIOUS_AFFILIATION',
  OTHER = 'OTHER',
  PENTECOSTAL = 'PENTECOSTAL',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
  PRESBYTERIAN_CHURCH = 'PRESBYTERIAN_CHURCH',
  REFORMED_CHURCH = 'REFORMED_CHURCH',
  ROMAN_CATHOLIC = 'ROMAN_CATHOLIC',
  SEVENTH_DAY_ADVENTIST = 'SEVENTH_DAY_ADVENTIST',
  SIKH = 'SIKH',
  SOCIETY_OF_FRIENDS = 'SOCIETY_OF_FRIENDS',
  SOUTHERN_BAPTIST_CONVENTION = 'SOUTHERN_BAPTIST_CONVENTION',
  UNITARIAN_UNIVERSALIST_ASSOCIATION = 'UNITARIAN_UNIVERSALIST_ASSOCIATION',
  UNITED_CHURCH_OF_CHRIST = 'UNITED_CHURCH_OF_CHRIST',
}

export enum SavedScholarshipStatus {
  APPLIED = 'APPLIED',
  AWARDED = 'AWARDED',
  NOT_AWARDED = 'NOT_AWARDED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  SAVED = 'SAVED',
}

export enum ScholarshipSortField {
  AWARD_AMOUNT = 'AWARD_AMOUNT',
  DEADLINE = 'DEADLINE',
}

export enum SocialCulturalReligiousAffiliation {
  ALL_COURSES_CAMPUS = 'ALL_COURSES_CAMPUS',
  ALL_COURSES_ONLINE = 'ALL_COURSES_ONLINE',
  ETHNICALLY_DIVERSE = 'ETHNICALLY_DIVERSE',
  FAITH_BASED = 'FAITH_BASED',
  HISPANIC_SERVING = 'HISPANIC_SERVING',
  HISTORICALLY_BLACK = 'HISTORICALLY_BLACK',
  MAJOR_SPORTS_PROGRAM = 'MAJOR_SPORTS_PROGRAM',
  MIX_CAMPUS_ONLINE_COURSES = 'MIX_CAMPUS_ONLINE_COURSES',
  NOT_SURE = 'NOT_SURE',
  ONCAMPUS_HOUSING = 'ONCAMPUS_HOUSING',
  TRIBAL_COLLEGE_UNIVERSITY = 'TRIBAL_COLLEGE_UNIVERSITY',
}

export enum StateCode {
  AA = 'AA',
  AE = 'AE',
  AK = 'AK',
  AL = 'AL',
  AP = 'AP',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NOT_SURE = 'NOT_SURE',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

/**
 * Supported survey page types
 */
export enum SurveyPageType {
  CUSTOM = 'CUSTOM',
  INVENTORY = 'INVENTORY',
  RECRUITMENT = 'RECRUITMENT',
  STANDARD = 'STANDARD',
}

export enum UtilitiesFeatureFlagType {
  BOOLEAN = 'BOOLEAN',
  JSON = 'JSON',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

export enum YearLevel {
  FOUR_YEAR = 'FOUR_YEAR',
  TWO_YEAR = 'TWO_YEAR',
  UNKNOWN = 'UNKNOWN',
}

export enum YesNoPreferNotRespond {
  NO = 'NO',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
  YES = 'YES',
}

export enum YesNoPreferNotRespondNotSure {
  NO = 'NO',
  NOT_SURE = 'NOT_SURE',
  PREFER_NOT_TO_RESPOND = 'PREFER_NOT_TO_RESPOND',
  YES = 'YES',
}

export interface CareerByClusterInput {
  profileId?: string | null;
  clusterCode: string;
}

export interface CareerByCodeInput {
  code: string;
  profileId?: string | null;
}

export interface CareerDeleteCareerInput {
  code: string;
}

export interface CareerRelatedCareersByCoolCareerInput {
  coolCareerCode: string;
}

export interface CareerSaveCareerInput {
  code: string;
  title: string;
}

export interface CareerSearchInput {
  search: string;
  limit: number;
  offset: number;
  profileId?: string | null;
  order?: CareerOrder | null;
}

export interface CareersByCollectionInput {
  collectionCode: string;
}

export interface CollegeAddAOSClickActivityInput {
  actionType: CollegeAOSClickActivityType;
  fice: string;
  url: string;
  trackingCampaignId?: number | null;
}

export interface CollegeAddAOSClickActivityWithAIDInput {
  aid: string;
  actionType: CollegeAOSClickActivityType;
  fice: string;
  url: string;
  trackingCampaignId?: number | null;
}

export interface CollegeAddSavedCollegeInput {
  fice: string;
  ipedsId?: string | null;
  mcocid: number;
  college: string;
  city: string;
  state: string;
}

export interface CollegeCollectionByIdInput {
  id: string;
  meta: CollegeCollectionByIdInputMeta;
}

export interface CollegeCollectionByIdInputMeta {
  limit: number;
  offset: number;
  orderByFields?: CollegeCollectionSortByField[] | null;
}

export interface CollegeCollectionSortByField {
  orderBy: EncouraCollegeSortField;
  orderDirection: OrderDirection;
}

export interface CollegeIsAOSCollegeWithAIDInput {
  aid: string;
  fice: string;
}

export interface CreateHelpDeskTicketInput {
  device: string;
  osVersion: string;
  appVersion: string;
  email: string;
  subject: string;
  description: string;
  userName: string;
}

export interface EncouraCollegeListConfig {
  hideCollegesWithoutMajors?: boolean | null;
}

export interface EncouraCollegePaginationInput {
  limit: number;
  offset: number;
  orderByFields?: EncouraCollegeSortByField[] | null;
}

export interface EncouraCollegeSortByField {
  orderBy: EncouraCollegeSortField;
  orderDirection: OrderDirection;
}

export interface EncouraCollegesFilterOptions {
  yearLevels?: YearLevel[] | null;
  religiousAffls?: string[] | null;
  controls?: Control[] | null;
  acceptRateBrackets?: EncouraAcceptRateBracket[] | null;
  majorCodes?: string[] | null;
  states?: string[] | null;
  regions?: EncouraRegion[] | null;
  averageHsGpaBrackets?: EncouraHsGpaBracket[] | null;
  outStateCostBrackets?: EncouraOutStateCostBracket[] | null;
  inStateCostBrackets?: EncouraOutStateCostBracket[] | null;
  institutionSizeBrackets?: EncouraInstitutionSizeBracket[] | null;
  name?: string | null;
  isHistoricallyBlack?: boolean | null;
  isTribal?: boolean | null;
  isHispanic?: boolean | null;
}

export interface EncouraLtiStudentSurveyInput {
  vendorName?: string | null;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  gender?: string | null;
  birthDate: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  line2?: string | null;
  phone?: string | null;
  email: string;
  highSchoolGraduationYear: string;
  hsCeeb?: string | null;
  hsClass?: string | null;
  hsId?: string | null;
  hsName?: string | null;
  programCode?: string | null;
  teacherId?: string | null;
  colName?: string | null;
  denom?: string | null;
  gpa: string;
  parentCols?: string | null;
  majors?: string[] | null;
  colChars?: string[] | null;
  ctypes?: string[] | null;
  decStates?: string[] | null;
  envirs?: string[] | null;
  hsPreps?: string[] | null;
  interests?: string[] | null;
  mBranchs?: string[] | null;
  mPlanInfos?: string[] | null;
  races?: string[] | null;
  rcvInfo?: string[] | null;
  specials?: string[] | null;
  sports?: string[] | null;
  professions?: string[] | null;
  importances?: string[] | null;
  applicares?: string[] | null;
  learnexps?: string[] | null;
  expects?: string[] | null;
  parentEmails?: string[] | null;
  sensitiveOptIn?: boolean | null;
  declaredCollege?: string[] | null;
  globalSourceReferrer: string;
  globalSourceType: GlobalSourceType;
  isTest?: boolean | null;
}

export interface EncourageUserAddEducatorConnectionInput {
  inviteCode: string;
  profileId?: string | null;
}

export interface EncourageUserChangePasswordInput {
  currentPassword: string;
  newPassword: string;
}

export interface EncourageUserCompleteForgotPasswordInput {
  email: string;
  newPassword: string;
  verificationCode: string;
}

export interface EncourageUserCreateAccountAcceptedPrivacyProgramsInput {
  privacyProgram: EncourageUserPrivacyProgram;
  termsAndConditionsVersion: string;
}

export interface EncourageUserCreateAccountInput {
  email: string;
  password: string;
  dateOfBirth: any;
  gender?: EncourageUserGender | null;
  communicationPreference?: EncourageUserCommunicationPreference | null;
  acceptedTermsAndConditions?: EncourageUserCreateAccountAcceptedPrivacyProgramsInput[] | null;
  phone?: string | null;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  addressStreet1?: string | null;
  addressStreet2?: string | null;
  addressCity?: string | null;
  addressState?: string | null;
  addressStateCode?: string | null;
  addressCountry?: string | null;
  addressCountryCode: string;
  addressPostalCode?: string | null;
  recaptchaCode?: string | null;
  visitorId?: string | null;
  inviteCode?: string | null;
  marketingCampaignId?: string | null;
}

export interface EncourageUserExistingUserInput {
  email: string;
  dateOfBirth?: any | null;
  addressPostalCode?: string | null;
}

export interface EncourageUserForgotPasswordInput {
  email: string;
}

export interface EncourageUserGenerateVisitorIdInput {
  seed?: string | null;
}

export interface EncourageUserImportMCODataInput {
  importToken: string;
}

export interface EncourageUserInput {
  visitorId?: string | null;
  studentKey?: number | null;
  displayName?: string | null;
  psatScores?: EncourageUserScoresInput | null;
  satScores?: EncourageUserScoresInput | null;
  profileId?: string | null;
  uiState?: EncourageUserUIStateDataInput | null;
}

export interface EncourageUserIsExistingUserInput {
  email: string;
}

export interface EncourageUserLoginInput {
  email: string;
  password: string;
  verifyTermsAndConditions?: EncourageUserPrivacyProgramsInput[] | null;
  acceptedTermsAndConditions?: EncourageUserPrivacyProgramsInput[] | null;
  registrationToken?: string | null;
}

export interface EncourageUserPrivacyProgramsInput {
  privacyProgram: EncourageUserPrivacyProgram;
  termsAndConditionsVersion: string;
}

export interface EncourageUserPushNotificationDataInput {
  profileId?: string | null;
}

export interface EncourageUserResendAccountVerificationCodeInput {
  email: string;
}

export interface EncourageUserScoresInput {
  math?: number | null;
  reading?: number | null;
}

export interface EncourageUserUIStateDataInput {
  mcoImportModalSeenDate?: any | null;
  mcoImportDate?: any | null;
  transition10To11thGradeCompletionDate?: any | null;
  transition10To11thGradeCongratulationsModalSeenDate?: any | null;
  transition10To11thGradePriorWarningModalSeenDate?: any | null;
  lastSeenACTScoreDate?: any | null;
  onboardingComplete?: boolean | null;
}

export interface EncourageUserUpdatePushNotificationDataInput {
  profileId?: string | null;
  topics?: EncourageUserPushNotificationTopic[] | null;
}

export interface EncourageUserVerifyAccountInput {
  email: string;
  verificationCode: string;
}

export interface JourneyUpdateLearnerMilestoneChecklistItemInput {
  milestoneUid: string;
  checklistItemUid: string;
  selected: boolean;
}

export interface JourneyUpdateLearnerMilestoneNoteInput {
  milestoneUid: string;
  body: string;
}

export interface JourneyUpdateLearnerMilestoneStatusInput {
  milestoneUid: string;
  status: LearnerMilestoneStatusType;
}

export interface MajorAreaProfileInput {
  code: string;
}

export interface MajorCollectionByCodeInput {
  code: string;
}

export interface MajorProfileInput {
  code: string;
}

export interface MajorSavedMajorInput {
  savedType?: string | null;
}

export interface MajorSearchInput {
  limit: number;
  offset: number;
  profileId?: string | null;
  search: string;
  order?: MajorOrder | null;
}

export interface ScholarshipFilter {
  stateList?: string[] | null;
  gradeList?: string[] | null;
  gpaList?: string[] | null;
  athleticsList?: string[] | null;
  raceList?: string[] | null;
  majorList?: string[] | null;
  careerList?: string[] | null;
  membershipList?: string[] | null;
  religionList?: string[] | null;
  interestList?: string[] | null;
  militaryList?: string[] | null;
  disabilityList?: string[] | null;
  circumstanceList?: string[] | null;
  savedStatusList?: string[] | null;
  testScoresRequired?: string[] | null;
}

export interface ScholarshipFilterInput {
  limit?: number | null;
  offset?: number | null;
  filter?: ScholarshipFilter | null;
  orderByFields?: ScholarshipOrderByField[] | null;
  profileId?: string | null;
}

export interface ScholarshipInput {
  sUuid: string;
  profileId?: string | null;
}

export interface ScholarshipOrderByField {
  orderBy: ScholarshipSortField;
  orderDirection: OrderDirection;
}

export interface ScholarshipSearchFilter {
  savedStatusList?: string[] | null;
}

export interface ScholarshipSearchInput {
  limit?: number | null;
  offset?: number | null;
  search: string;
  filter?: ScholarshipSearchFilter | null;
  orderByFields?: ScholarshipOrderByField[] | null;
  profileId?: string | null;
}

export interface SetProfileCollegeRecruitmentIndInput {
  collegeRecruitmentInd?: boolean | null;
}

export interface UpdateLearnerMilestoneStatusInput {
  milestoneUid: string;
  profileId: string;
  status: LearnerMilestoneStatusType;
}

export interface UpdateProfileContactEmailInput {
  newEmail: string;
  confirmEmail: string;
}

export interface UpdateProfileMajorAddSavedMajorInput {
  code: string;
  savedType?: string | null;
}

export interface UpdateProfileMajorDeleteSavedMajorInput {
  code: string;
  savedType?: string | null;
}

export interface UpdateProfileQuizUpdateInterestInventoryInput {
  version: string;
  responses?: UpdateProfileQuizUpdateInterestInventoryResponsesInput[] | null;
}

export interface UpdateProfileQuizUpdateInterestInventoryResponsesInput {
  id: string;
  value?: string | null;
}

export interface UpdateSavedScholarshipInput {
  sUuid: string;
  profileId?: string | null;
  status?: SavedScholarshipStatus | null;
}

export interface UtilitiesUpdateEmailSettingsInput {
  aid: string;
  advice?: boolean | null;
  emailActive?: boolean | null;
  products?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
